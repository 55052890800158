@import '~assets/css/variable.scss';
@import '~assets/css/styles.scss';

.container {
  display: inline-block;

  .text {
    color: $successIconGreen;
    font-size: 14px;
    text-transform: lowercase;
    @include media(S) {
      font-size: 12px;
    }
  }
}
