@import '~assets/css/styles.scss';
@import '~assets/css/variable.scss';

.container {
  h2 {
    letter-spacing: 2px;
    font-size: 30px;
  }

  .card {
    box-shadow: 1px 2px 33px 10px rgba(151, 151, 181, 0.25);
    border-radius: 20px 0 0 20px;
    display: flex;
    @include media(S) {
      flex-wrap: wrap;
      display: inline-block;
      text-align: center;
      border-radius: 20px;
    }

    img {
      width: 400px;
      @include media(S) {
        display: block;
        margin: auto;
        width: 100%;
        border-radius: 20px;
      }
    }

    .content {
      position: relative;
      display: inline;
      text-align: right;
      padding: 40px;

      p {
        text-align: justify;
        text-justify: inter-word;
        letter-spacing: 1px;
        line-height: 1.6;
        position: relative;
        top: 40px;
        @include media(S) {
          margin: 20px;
          top: 5px;
        }
      }

      Button {
        position: absolute;
        padding: 4px 25px;
        right: 40px;
        bottom: 40px;
        text-transform: none;
        &:hover {
          background-color: $hoverEffectColor;
        }
        @include media(S) {
          position: relative;
          display: block;
          margin: 40px auto auto;
          right: 0;
          bottom: 0;
        }
      }
    }
  }
}
