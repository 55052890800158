.boxMobile {
  margin: 0 32px;
  margin-bottom: 20px;
  .divider {
    margin-top: 16px;
  }
  h2 {
    font-size: 18px;
  }
}
.box {
  min-width: 300px;
  margin-bottom: 20px;
  .divider {
    margin-top: 16px;
  }
  h2 {
    font-size: 24px;
  }
}
.header {
  display: flex;
  justify-content: space-between;
  .hiddenButton {
    visibility: hidden;
  }
}
h3 {
  font-size: 18px;
  margin-bottom: 12px;
}
.locationSelect {
  min-width: 130px;
}
.buttonContainer {
  margin-top: 32px;
  display: flex;
  justify-content: space-between;
  .resetButton {
    height: 32px;
    padding: 0 20px;
    text-transform: none;
  }
  .searchButton {
    height: 32px;
    padding: 0 80px;
    text-transform: none;
  }
}
