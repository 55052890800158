@import '~assets/css/styles.scss';
@import '~assets/css/variable.scss';

.container {
  margin-top: 40px;

  .title {
    color: $hoverEffectColor;
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 20px;
  }

  .content {
    overflow: hidden;
    position: relative;
  }

  .viewMore {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 30px;
    color: $hoverEffectColor;
    font-size: 18px;

    :hover {
      cursor: pointer;
    }
  }
}
