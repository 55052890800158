@import '~assets/css/styles.scss';

.title {
  margin: 0;
  display: none;
  @include media(S) {
    display: block;
    margin-left: 24px;
    font-size:24px;
    line-height:30px;
  }
}
