@import '~assets/css/variable.scss';
@import '~assets/css/styles.scss';
@import '../common/passwordPageLayout.module.scss';

.list {
  font-size: 14px;
  margin: 5px 0;
  @include media(S) {
    font-size: 10px;
  }
}

