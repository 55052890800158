@import "~assets/css/variable.scss";

.topWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 40px 0;
  position: relative;

  .menuIcon {
    margin-right: 5px;
  }

  .title {
    display: flex;
    color: $primaryColor;
    font-size: 18px;
    font-weight: 700;
    align-items: center;
    margin: 0;
  }

  .iconBack {
    width: 18px;
    height: 18px;
    color: $primaryColor;
    position: absolute;
    left: 20px;
    top: 5px;

    &:hover {
      background-color: $secondaryColor;
    }
  }

  .iconClose {
    width: 18px;
    height: 18px;
    color: $primaryColor;
    position: absolute;
    right: 20px;
    top: 5px;

    &:hover {
      background-color: $secondaryColor;
    }
  }
}

.sidemenuItem {
  display: flex;
  font-size: 14px;
  font-weight: 400;
  color: $primaryColor;
  margin: 20px 0 16px 20px;
  justify-content: space-between;
  border: none;
  background-color: #fff;
  width: 90%;

  p {
    margin-top: 3px;
  }
}

.divider {
  margin: 16px 0;
}
