@import '~assets/css/variable.scss';

.toolBar {
  background-color: $secondaryColor;
}

.sidemenu {
  height: 100%;
  width: 100%;
  z-index: 100;
  position: fixed;
  background-color: $secondaryColor;
}
