@import '~assets/css/variable.scss';

.authentication {
  display: flex;
  .button {
    &:hover {
      background-color: $hoverEffectColor;
    }
  }
};
