@import '~assets/css/variable.scss';

.topWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 40px 0;
  position: relative;

  .menuIcon {
    margin-right: 5px; 
  }

  .title {
    display: flex;
    color: $primaryColor;
    font-size: 18px;
    font-weight: 700;
    align-items: center;
    margin: 0;
  }

  .iconButton {
    width: 18px;
    height: 18px;
    color: $primaryColor;
    position: absolute;
    right: 20px;

    &:hover {
      background-color: $secondaryColor;
    }
  }
}

.sidemenuItem {
  display: block;
  font-size: 14px;
  font-weight: 400;
  color: $primaryColor;
  text-decoration: none;
  margin: 16px 0;
  margin-left: 20px;
}

.divider {
  margin: 16px 0;
}
