@import '~assets/css/styles.scss';
@import '~assets/css/variable.scss';

.accordion {
  margin-top: 40px;

  .title {
    color: $hoverEffectColor;
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 20px;
  }

  .content {
    overflow: hidden;
  }
}
