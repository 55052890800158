@import '~assets/css/variable.scss';
@import '~assets/css/styles.scss';

.container {
  background-image: url('../../../assets/images/search-background.png');
  justify-content: center;
  background-size: cover;
  background-position: center;

  .wrapper {
    padding-top: 200px;
    padding-bottom: 100px;

    @include media(S) {
      padding-top: 100px;
      padding-bottom: 70px;
      margin: 0 70px;
    }

    .searchTitle {
      font-size: 48px;
      color: $secondaryColor;
      text-align: center;
      margin-bottom: 10px;
  
      @include media(S) {
        font-size: 24px;
      }
    }

    .seachBarWrapper {
      width: 600px;
      margin: 0 auto;

      @include media(S) {
        display: none;
      }
    }
  }
}
