@import '~assets/css/variable.scss';
@import '~assets/css/styles.scss';

.information {
  margin: auto;
  width: 100%;
  max-width: 550px;
  @include media(S) {
    margin: auto 25px;
    max-width: 900px;
  }

  .title {
    margin: 0;
    margin-bottom: 30px;
    font-size: 36px;
    font-weight: 900;
    line-height: 45px;
    @include media(S) {
      font-size: 20px;
      line-height: 22px;
    }
  }

  .sent {
    margin: 20px 0;
    @include media(S) {
      font-size: 14px;
      line-height: 20px;
      margin: 10px 0;
    }
  }
}
