@import '~assets/css/styles.scss';

.container {
  margin: 80px 0;
  
  h2 {
    letter-spacing: 2px;
    font-size: 30px;
  }

  .list {
    display: flex;
    padding: 10px 150px;
    @include media(S) {
      flex-direction: column;
    }

    .item {
      flex-direction: column;
      padding: 0;

      .circle {
        box-shadow: 1px 2px 33px -1px rgba(151, 151, 181, 0.25);
        border-radius: 50%;
        padding: 40px;

        .icon {
          font-size: 60px;
        }
      }

      .span {
        margin-top: 30px;
        font-size: 20px;
        font-weight: bold;
        @include media(S) {
          margin: 30px 0;
          font-size: 10px;
          text-align: center;
          width: 150px;
        }
      }
    }
  }

  .arrow {
    font-size: 80px;
    margin-top: 35px;
    @include media(S) {
      margin-top: 10px;
      font-size: 40px;
      display: none;
    }
  }
}
