.container {
  margin: 0 32px;
  margin-top: 20px;
  font-size: 12px;
  h1 {
    display: inline-block;
    font-size: 24px;
    &:after {
      content: '';
      display: block;
      margin-top: 2px;
      margin-right: -16px;
      border: 2px solid black;
    }
  }
  h2 {
    font-size: 18px;
    font-weight: 600;
  }
  .buttonContainer {
    display: flex;
    justify-content: space-between;
  }
}
