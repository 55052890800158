@import '~assets/css/styles.scss';
@import '~assets/css/variable.scss';

.container {
  text-align: center;
  h2 {
    letter-spacing: 2px;
    font-size: 30px;
    text-align: left;
  }
  .expertsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
  .expertsContainerLaptop {
    display: flex;
    justify-content: space-between;
  }
  .expertBox {
    margin: 10px auto;
  }
  .arrowContainer {
    display: flex;
    align-items: center;
    .arrow {
      height: 40px;
      width: 40px;
    }
    &:disabled {
      color: $primaryColor;
    }
  }
  .buttonContainer {
    display: flex;
    justify-content: center;
    .button {
      margin-top: 50px;
      padding: 0 90px;
      height: 50px;
      text-transform: none;
      font-size: 18px;
      &:hover {
        background-color: $hoverEffectColor;
      }
    }
  }
}
