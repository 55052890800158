@import '~assets/css/styles.scss';
@import '~assets/css/variable.scss';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  padding: 50px auto;
    .card {
    width: 300px;
    text-align: center;
    border: none;
    box-shadow: none;
    &:hover {
      background-color: transparent;
    }
    @include media(S) {
      width: 200px;
    }
    .avatar {
      padding: auto;
      margin: 50px auto auto auto;
      width: 231px;
      height: 231px;
      border-radius: 50%;
      @include media(S) {
        margin: auto;
        width: 81px;
        height: 81px;
      }
    }
  }
  .name {
    font-weight: bold;
    font-size: 36px;
    @include media(S) {
      font-size: 18px;
    }
  }
  .email {
    padding-bottom: 20px;
    color: $locationColor;
    font-size: 18px;
    @include media(S) {
      font-size: 14px;
    }
  }
}
