@import '~assets/css/variable.scss';
@import '~assets/css/styles.scss';

.desktopContainer {
  align-items: center;
  justify-content: space-between;
  padding: 22px 80px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.15);
  display: flex;
  @include media(S) {
    display: none;
  }
};

.mobileContainer {
  display: none;

  @include media(S) {
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 1px 10px 0px rgba(0, 0, 0, 0.15);
    display: flex;
  }
};