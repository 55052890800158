@import '~assets/css/styles.scss';
@import '~assets/css/variable.scss';

.desktopContainer {
  @include media(S) {
    display: none;
  }
  .menu {
    display: flex;
    border-bottom: 1px solid #e3e3e3;
    justify-content: center;

    .tab {
      background-color: $secondaryColor;
      font-size: 20px;
      border: none;
      padding-bottom: 10px;
      flex-grow: 1;
      &:hover {
        border-bottom: 3px solid $hoverEffectColor;
        color: $hoverEffectColor;
        cursor: pointer;
      }
    }
    .activetab {
      background-color: $secondaryColor;
      font-size: 20px;
      border: none;
      padding-bottom: 10px;
      flex-grow: 1;
      border-bottom: 3px solid $hoverEffectColor;
      color: $hoverEffectColor;
      cursor: pointer;
    }
  }
}
.mobileContainer {
  display: none;
  @include media(S) {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .sidemenuItem {
    display: flex;
    flex-direction: row;
    font-size: 18px;
    font-weight: 400;
    color: $primaryColor;
    justify-content: space-between;
    border: none;
    background-color: $secondaryColor;
    width:100%;
    cursor: pointer;
    .text {
      padding: auto;
      margin-left: 10px;
    }
    .arrow {
      margin-right: 10px;
    }
  }
  .divider {
    margin: 16px 0;
  }
}
