@import '~assets/css/styles.scss';
@import '~assets/css/variable.scss';

.container {
  margin-bottom: 20px;

  .client {
    display: flex;
    flex-direction: row;
    margin: 10px 0;

    .avatar {
      width: 25px;
      margin-right: 10px;
    }
  }

  .comment {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .date {
    font-size: 10px;
    color: $locationColor;
  }
}

