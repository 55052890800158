.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-grow: 2;
}

.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 70%;
  padding-left: 10%;
  padding-right: 10%;
}
