@import '~assets/css/variable.scss';

.breadcrumbItem {
  text-decoration: none;
  color: $locationColor;

  &:hover {
    text-decoration: underline;
  }
}
